<script setup>
	import HistoryItem from "@/components/HistoryItem.vue";
	import BackgroundHomeHeaderLink from "@/components/icons/BackgroundHomeHeaderLink.vue";
	import IconLogo from "@/components/icons/IconLogo.vue";
	import ScratchCard from "@/components/ScratchCard.vue";
	import { useUserStore } from "@/store/userStore";
	import { computed, ref, watch } from "vue";

	const props = defineProps({
		isLoading: {
			type: Boolean,
		},
	});

	const userStore = useUserStore();
	const attempts = ref("");
	userStore.$subscribe(() => {
		attempts.value = userStore.getUserAttempts;
	});
	attempts.value = userStore.getUserAttempts;
	const attemptsText = computed(() => {
		if (attempts.value === 1) {
			return "попытка";
		} else if (attempts.value > 1 && attempts.value < 5) {
			return "попытки";
		} else {
			return "попыток";
		}
	});
	const logoHidden = ref(true);
	watch(props, () => {
		logoHidden.value = false;
		if (!props.isLoading) {
			setTimeout(() => {
				logoHidden.value = true;
			}, 2000);
		}
	});
	const formatDate = (date) => {
		const d = new Date(date);
		return `${d.getHours() > 9 ? "" : "0"}${d.getHours()}:${
			d.getMinutes() > 9 ? "" : "0"
		}${d.getMinutes()}`;
	};
	const wasteAttempt = () => {
		attempts.value -= 1;
		userStore.setUserAttempts({
			count_attempts: attempts.value,
		});
	};
</script>

<template>
	<div class="home">
		<div class="container">
			<IconLogo
				class="home__logo"
				:class="{ 'home__logo--hidden': logoHidden }"
			/>
			<div class="home-header">
				<router-link to="/check" class="home-header-link">
					<span>{{ attempts }}</span>
					{{ attemptsText }}
					<BackgroundHomeHeaderLink class="home-header-link__background" />
				</router-link>
				<img
					src="../assets/img/logo-14-years.png"
					alt=""
					class="home-header__logo"
				/>
				<router-link to="/prizes" class="home-header-link">
					Призы
					<img src="../assets/img/prize.png" alt="" />
					<BackgroundHomeHeaderLink class="home-header-link__background" />
				</router-link>
			</div>
			<div class="home-content">
				<img
					v-if="attempts === 0"
					class="home-content__no-attempts"
					src="../assets/img/main-cat.svg"
					alt=""
				/>
				<ScratchCard v-else @waste-attempt="wasteAttempt()" />
			</div>
			<routerLink to="/check" class="home-content__button">
				Загрузить <span>чек</span>
			</routerLink>
			<div class="users-prizes">
				<p class="users-prizes__title">Выигрыши пользователей</p>
				<div class="users-prizes__row">
					<template v-for="(award, i) in userStore.getLastAwards" :key="i">
						<HistoryItem
							is-home
							:title="award.award.name || 'Null'"
							:date="formatDate(award.award.created_at)"
							:emoji="award.award.image || ''"
							:recipient="`+7 *** *** ${award.phone}`"
						/>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
	.home {
		padding: 12px 0px 24px;
		min-height: 100%;
		display: flex;
		flex-direction: column;
		&__logo {
			margin: 0px auto 12px;
			transition: 0.3s ease;
			&--hidden {
				margin-top: -44px;
				opacity: 0;
			}
		}
	}
	.home-content {
		margin-top: 24px;
		margin-bottom: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		&__button {
			margin-top: auto;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 6px;
			padding: 12px 0px;
			border-radius: 100px;
			color: var(--color-white-100, #fff);
			font-size: 16px;
			font-weight: 700;
			background: var(--color-main-100, #561750);
			box-shadow: 0px 4px 0px 0px #3a0e36;
			transition: 0.3s ease;
			position: relative;
			span {
				font-size: 10px;
				letter-spacing: 0.8px;
				text-transform: uppercase;
				opacity: 0.4;
			}
			&::after {
				content: "+1 попытка";
				color: var(--color-white-100, #fff);
				font-size: 12px;
				font-weight: 400;
				position: absolute;
				bottom: -8px;
				padding: 2px 6px;
				border-radius: 100px;
				transition: 0.3s ease;
				background: #471142;
				box-shadow: 0px 4px 0px 0px #3a0e36;
			}
			&:active {
				background: #6f1e67;
				box-shadow: 0px 0px 0px 0px #3a0e36;
				transform: translateY(4px);
				&::after {
					background: #561750;
					box-shadow: 0px 0px 0px 0px #3a0e36;
				}
			}
		}
	}
	.home-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		&__logo {
			width: 48px;
			height: 50px;
		}
	}
	.home-header-link {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 6px;
		color: var(--color-main-100, #561750);
		font-size: 12px;
		font-weight: 700;
		position: relative;
		line-height: 100%;
		text-transform: uppercase;
		width: 120px;
		height: 50px;
		z-index: 1;
		transition: 0.3s ease;
		:deep(.home-header-link__background) {
			position: absolute;
			width: 100%;
			height: 50px;
			transition: 0.3s ease;
			z-index: -1;
			filter: drop-shadow(0px 4px 0px #c2c2c2);
		}
		span {
			font-size: 28px;
		}
		&:last-child {
			:deep(.home-header-link__background) {
				transform: rotate(180deg);
				filter: drop-shadow(0px -4px 0px #c2c2c2);
			}
		}
		&:active {
			transform: translateY(4px);
			:deep(.home-header-link__background) {
				filter: drop-shadow(0px 0px 0px #c2c2c2);
			}
		}
	}
	.users-prizes {
		display: flex;
		flex-direction: column;
		gap: 16px;
		margin-top: 40px;
		&__title {
			color: var(--color-grey-100, #5f6062);
			font-size: 17px;
			font-weight: 700;
		}
		&__row {
			display: flex;
			gap: 4px;
			align-items: flex-start;
			overflow: auto;
			margin: 0 -10px 0;
			width: calc(100% + 20px);
			padding: 0px 10px;
		}
	}
</style>
