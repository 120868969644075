<template>
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M15.3319 8.83744C15.0525 9.1867 14.5429 9.24332 14.1936 8.96392L12.8099 7.85694V14.5709C12.8099 15.0182 12.4473 15.3808 12 15.3808C11.5528 15.3808 11.1902 15.0182 11.1902 14.5709V7.85689L9.8064 8.96392C9.45714 9.24332 8.9475 9.1867 8.66809 8.83744C8.38869 8.48818 8.44531 7.97854 8.79457 7.69914L11.4912 5.54183C11.6286 5.43073 11.8031 5.36366 11.9932 5.36209C11.9955 5.36207 11.9978 5.36206 12 5.36206L12.0026 5.36206C12.196 5.36268 12.3735 5.43108 12.5124 5.54475L15.2054 7.69914C15.5547 7.97854 15.6113 8.48818 15.3319 8.83744Z"
			fill="white"
		/>
		<rect
			opacity="0.5"
			x="3.68799"
			y="18"
			width="16.6241"
			height="1.5"
			rx="0.75"
			fill="white"
		/>
	</svg>
</template>
