<script setup>
	import CharacterCat from "@/components/icons/CharacterCat.vue";
	import { postSupportCreate } from "@/services/apiService";
	import { useUserStore } from "@/store/userStore";
	import { ref } from "vue";

	const text = ref("");
	const sended = ref(false);
	const userStore = useUserStore();
	async function handleSubmit() {
		if (text.value.length > 0) {
			await postSupportCreate(userStore.getUserId, text.value);
			text.value = "";
			sended.value = true;
		}
	}
</script>

<template>
	<div class="feedback">
		<div class="container">
			<div class="feedback__container">
				<h1 class="title">Помощь</h1>
				<div class="feedback__card card card--large">
					<p class="feedback__title">
						{{
							sended ? "Ваше сообщение отправлено" : "С чем возникла проблема?"
						}}
					</p>
					<form
						v-if="!sended"
						action="POST"
						class="feedback-form"
						@submit.prevent="handleSubmit"
					>
						<textarea
							v-model="text"
							class="feedback-form__textarea"
							placeholder="Опишите проблему..."
						></textarea>
						<button
							:class="{
								'feedback-form__button': true,
								'feedback-form__button--active': text.length > 0,
							}"
						>
							Отправить
						</button>
					</form>
					<CharacterCat v-if="sended" class="feedback__cat" />
					<router-link
						v-if="sended"
						to="/"
						class="feedback-form__button--active feedback-form__button"
					>
						На главный экран
					</router-link>
				</div>
				<p class="feedback__text text card card--large">
					{{
						sended
							? "Скоро наши сотрудники свяжутся с Вами в Телеграм для решения проблемы."
							: "Оставьте сообщение в поле ниже, чтобы сотрудники технической поддержки связались с Вами в Телеграм для решения проблемы."
					}}
				</p>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
	.feedback {
		padding: 12px 0px 24px;
		&__text {
			margin-top: 12px;
		}
		&__card {
			margin-top: 24px;
		}
		&__title {
			color: var(--color-main-100, #561750);
			font-size: 20px;
			font-weight: 700;
		}
		&__cat {
			margin-top: 24px;
			margin-left: auto;
			display: block;
			margin-right: auto;
		}
	}
	.feedback-form {
		margin-top: 24px;
		&__textarea {
			padding: 20px 12px;
			background: var(--color-grey-10);
			width: 100%;
			min-height: 230px;
			border-radius: 12px;
			font-size: 16px;
			color: var(--color-black-100);
			font-weight: 700;
			&::placeholder {
				opacity: 0.3;
			}
		}
		&__button {
			margin: 24px auto 0px;
			background: var(--color-grey-100);
			color: rgba($color: #fff, $alpha: 0.5);
			font-size: 14px;
			font-weight: 700;
			max-width: 300px;
			width: 100%;
			height: 48px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 100px;
			transition: 0.3s ease;
			&--active {
				background: var(--color-main-100);
				color: var(--color-white-100, #fff);
				&:active {
					background: #6f1e67;
				}
			}
		}
	}
</style>
