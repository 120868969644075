<template>
	<svg
		width="117"
		height="50"
		viewBox="0 0 117 50"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		preserve-aspect-ratio="none"
	>
		<path
			d="M20.8737 0.912723L97.8737 4.27962C108.569 4.7473 117 13.5546 117 24.2605V27.047C117 37.8565 108.411 46.7099 97.6067 47.0378L20.6067 49.3746C9.32846 49.7169 0 40.6672 0 29.3838V20.8936C0 9.50507 9.49599 0.415222 20.8737 0.912723Z"
			fill="#F5F5F5"
		/>
	</svg>
</template>
