// src/services/apiService.js

const API_URL = "https://api.scratch.pairmax.digital/api";

export const getClientCountAttempts = async (userId) => {
	try {
		const response = await fetch(
			`${API_URL}/client/get-count-attempts?telegram_id=${userId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
		if (!response.ok) {
			throw new Error(`Ошибка: ${response.status}`);
		}
		const data = await response.json();
		return data;
	} catch (error) {
		console.error("Ошибка получения количества попыток", error);
		throw error;
	}
};
export const getClientAward = async (userId) => {
	try {
		const response = await fetch(
			`${API_URL}/client/get-award?telegram_id=${userId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
		if (!response.ok) {
			throw new Error(`Ошибка: ${response.status}`);
		}
		const data = await response.json();
		return data;
	} catch (error) {
		console.error("Ошибка получения награды", error);
		throw error;
	}
};
export const getLastClientAward = async (userId) => {
	try {
		const response = await fetch(
			`${API_URL}/client/last-awards?telegram_id=${userId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
		if (!response.ok) {
			throw new Error(`Ошибка: ${response.status}`);
		}
		const data = await response.json();
		return data;
	} catch (error) {
		console.error("Ошибка получения награды", error);
		throw error;
	}
};
export const getClientHistory = async (userId) => {
	try {
		const response = await fetch(
			`${API_URL}/client/history?telegram_id=${userId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
		if (!response.ok) {
			throw new Error(`Ошибка: ${response.status}`);
		}
		const data = await response.json();
		return data;
	} catch (error) {
		console.error("Ошибка получения истории", error);
		throw error;
	}
};
export const getReceiptHistory = async (userId) => {
	try {
		const response = await fetch(
			`${API_URL}/receipt/history?telegram_id=${userId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
		if (!response.ok) {
			throw new Error(`Ошибка: ${response.status}`);
		}
		const data = await response.json();
		return data;
	} catch (error) {
		console.error("Ошибка получения истории чеков", error);
		throw error;
	}
};
export const postReceiptCreate = async (userId, formdata) => {
	try {
		const response = await fetch(
			`${API_URL}/receipt/create?telegram_id=${userId}`,
			{
				method: "POST",
				body: formdata,
			}
		);
		if (!response.ok) {
			throw new Error(`Ошибка: ${response.status}`);
		}
		const data = await response.json();
		return data;
	} catch (error) {
		console.error("Ошибка создания чека", error);
		throw error;
	}
};
export const postSupportCreate = async (userId, text) => {
	try {
		const response = await fetch(
			`${API_URL}/support/create?telegram_id=${userId}&text=${text}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
		if (!response.ok) {
			throw new Error(`Ошибка: ${response.status}`);
		}
		const data = await response.json();
		return data;
	} catch (error) {
		console.error("Ошибка обратной связи", error);
		throw error;
	}
};
export const getClientHasPhone = async (userId) => {
	try {
		const response = await fetch(
			`${API_URL}/client/has-phone?telegram_id=${userId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
		if (!response.ok) {
			throw new Error(`Ошибка: ${response.status}`);
		}
		const data = await response.json();
		return data;
	} catch (error) {
		console.error("Ошибка проверки телефона", error);
		throw error;
	}
};
