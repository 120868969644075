<script setup>
	import HistoryItem from "@/components/HistoryItem.vue";
	import { useUserStore } from "@/store/userStore";

	const userStore = useUserStore();
	const formatDate = (date) => {
		const d = new Date(date);
		return `${d.getHours() > 9 ? "" : "0"}${d.getHours()}:${
			d.getMinutes() > 9 ? "" : "0"
		}${d.getMinutes()}`;
	};
	const formatDateGroup = (date) => {
		const d = new Date(date);
		return `${d.getDate() > 9 ? "" : "0"}${d.getDate()}.${
			d.getMonth() + 1 > 9 ? "" : "0"
		}${d.getMonth() + 1}.${d.getFullYear()}`;
	};
</script>

<template>
	<div class="history">
		<div class="container">
			<div class="history__container">
				<h1 class="title">История призов</h1>
				<div class="history__col card card--large">
					<p class="history__title">
						{{
							userStore.getHistoryAwards.length > 0
								? "Мои выигрыши"
								: "Здесь появятся ваши выигрыши"
						}}
					</p>
					<div class="history__group">
						<template v-for="(award, i) in userStore.getHistoryAwards" :key="i">
							<HistoryItem
								:title="award.name || 'Null'"
								:date="formatDate(award.created_at)"
								recipient="Это вы"
							/>
						</template>
						<!-- <HistoryItem title="Стикерпак" date="17:38" recipient="Это вы" /> -->
						<router-link to="/check" class="file__button">
							Загрузить чек
						</router-link>
					</div>
					<p class="history__title">Выигрыши других пользователей</p>
					<template
						v-for="(group, indexGroup) in [...userStore.getLastAwards].filter(
							(a, index) =>
								new Date(a.award.created_at).getDate() !==
								new Date(
									userStore.getLastAwards[index - 1]?.award.created_at
								).getDate()
						)"
						:key="indexGroup"
					>
						<p class="history__title">
							{{ formatDateGroup(group.award.created_at) }}
						</p>
						<div class="history__group">
							<template
								v-for="(award, i) in [...userStore.getLastAwards].filter(
									(a) =>
										new Date(a.award.created_at).getDate() ===
										new Date(group.award.created_at).getDate()
								)"
								:key="i"
							>
								<HistoryItem
									:title="award.award.name || 'Null'"
									:date="formatDate(award.award.created_at)"
									:recipient="`+7 *** *** ${award.phone}`"
								/>
							</template>
						</div>
					</template>
					<!-- <p class="history__title">06.09.2024</p>
					<div class="history__group">
						<HistoryItem
							title="10 000 баллов WINCLUB"
							date="17:38"
							recipient="+7 *** *** 98 26"
						/>
						<HistoryItem
							title="Стикерпак"
							date="17:38"
							recipient="+7 *** *** 98 26"
						/>
						<HistoryItem
							title="10 000 баллов WINCLUB"
							date="17:38"
							recipient="+7 *** *** 98 26"
						/>
						<HistoryItem
							title="Стикерпак"
							date="17:38"
							recipient="+7 *** *** 98 26"
						/>
						<HistoryItem
							title="10 000 баллов WINCLUB"
							date="17:38"
							recipient="+7 *** *** 98 26"
						/>
						<HistoryItem
							title="Стикерпак"
							date="17:38"
							recipient="+7 *** *** 98 26"
						/>
					</div>
					<p class="history__title">06.09.2024</p>
					<div class="history__group">
						<HistoryItem
							title="10 000 баллов WINCLUB"
							date="17:38"
							recipient="+7 *** *** 98 26"
						/>
						<HistoryItem
							title="Стикерпак"
							date="17:38"
							recipient="+7 *** *** 98 26"
						/>
						<HistoryItem
							title="10 000 баллов WINCLUB"
							date="17:38"
							recipient="+7 *** *** 98 26"
						/>
						<HistoryItem
							title="Стикерпак"
							date="17:38"
							recipient="+7 *** *** 98 26"
						/>
						<HistoryItem
							title="10 000 баллов WINCLUB"
							date="17:38"
							recipient="+7 *** *** 98 26"
						/>
						<HistoryItem
							title="Стикерпак"
							date="17:38"
							recipient="+7 *** *** 98 26"
						/>
					</div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
	.history {
		padding: 12px 0px 24px;
		&__col {
			display: flex;
			flex-direction: column;
			gap: 16px;
			margin-top: 24px;
		}
		&__group {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 4px;
		}
		&__title {
			color: var(--color-grey-100, #5f6062);
			text-align: center;
			font-size: 14px;
			font-weight: 700;
			opacity: 0.5;
		}
	}
</style>
