<template>
	<svg
		width="150"
		height="150"
		viewBox="0 0 150 150"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M97.4737 98.8093C97.4737 126.566 75.841 149 49.2368 149C22.6327 149 1 126.566 1 98.8093C1 71.0527 22.6327 48.6187 49.2368 48.6187C75.841 48.6187 97.4737 71.0527 97.4737 98.8093Z"
			fill="#4F1649"
			stroke="black"
			stroke-width="2"
		/>
		<path
			d="M16.973 96V85H25.3295V86.9175H19.6355V89.0659H21.6831C22.6793 89.0659 23.5381 89.2074 24.2596 89.4902C24.9852 89.7695 25.5447 90.1652 25.9383 90.6772C26.3318 91.1893 26.5286 91.7926 26.5286 92.4873C26.5286 93.1784 26.3318 93.7889 25.9383 94.3188C25.5447 94.8452 24.9852 95.257 24.2596 95.5542C23.5381 95.8514 22.6793 96 21.6831 96H16.973ZM19.6355 94.1309H21.6831C22.1587 94.1309 22.5604 94.0557 22.8883 93.9053C23.2163 93.7513 23.4643 93.549 23.6324 93.2983C23.8045 93.0441 23.8906 92.7666 23.8906 92.4658C23.8906 92.029 23.7 91.6602 23.3188 91.3594C22.9416 91.055 22.3964 90.9028 21.6831 90.9028H19.6355V94.1309Z"
			fill="white"
		/>
		<path
			d="M30.4224 96H27.5693L31.9167 85H35.3478L39.689 96H36.8359L33.6814 87.5137H33.583L30.4224 96ZM30.2441 91.6763H36.9835V93.4917H30.2441V91.6763Z"
			fill="white"
		/>
		<path
			d="M40.5222 96V94.1309L40.8789 94.104C41.338 94.0718 41.709 93.9196 41.9918 93.6475C42.2747 93.3717 42.4858 92.9331 42.6252 92.3315C42.7687 91.7264 42.8609 90.9136 42.9019 89.8931L43.111 85H51.6581V96H49.0694V86.8638H45.5829L45.3861 90.6934C45.3205 91.9251 45.1524 92.9313 44.8819 93.7119C44.6154 94.4925 44.1891 95.069 43.6029 95.4414C43.0208 95.8138 42.2235 96 41.2109 96H40.5222Z"
			fill="white"
		/>
		<path
			d="M53.1708 96V94.1309L53.5274 94.104C53.9865 94.0718 54.3575 93.9196 54.6404 93.6475C54.9233 93.3717 55.1344 92.9331 55.2737 92.3315C55.4172 91.7264 55.5095 90.9136 55.5504 89.8931L55.7595 85H64.3067V96H61.7179V86.8638H58.2314L58.0347 90.6934C57.9691 91.9251 57.801 92.9313 57.5304 93.7119C57.264 94.4925 56.8376 95.069 56.2514 95.4414C55.6693 95.8138 54.872 96 53.8595 96H53.1708Z"
			fill="white"
		/>
		<path
			d="M68.2543 89.0659H71.2551C72.2553 89.0659 73.1162 89.2074 73.8377 89.4902C74.5633 89.7695 75.1228 90.1652 75.5164 90.6772C75.9099 91.1893 76.1046 91.7926 76.1005 92.4873C76.1046 93.1784 75.9099 93.7889 75.5164 94.3188C75.1228 94.8452 74.5633 95.257 73.8377 95.5542C73.1162 95.8514 72.2553 96 71.2551 96H66.5511V85H69.2074V94.1309H71.2551C71.7347 94.1309 72.1385 94.0557 72.4664 93.9053C72.7944 93.7513 73.0424 93.549 73.2105 93.2983C73.3826 93.0441 73.4667 92.7666 73.4626 92.4658C73.4667 92.029 73.2781 91.6602 72.8969 91.3594C72.5197 91.055 71.9725 90.9028 71.2551 90.9028H68.2543V89.0659ZM79.7838 85V96H77.1274V85H79.7838Z"
			fill="white"
		/>
		<path
			d="M11.6702 117.818L8 104.976H10.9624L13.0856 113.899H13.1921L15.5345 104.976H18.071L20.4072 113.918H20.5199L22.6431 104.976H25.6055L21.9354 117.818H19.2923L16.8497 109.422H16.7495L14.3132 117.818H11.6702Z"
			fill="white"
		/>
		<path d="M29.7689 104.976V117.818H27.057V104.976H29.7689Z" fill="white" />
		<path
			d="M42.7288 104.976V117.818H40.3864L34.806 109.735H34.7121V117.818H32.0002V104.976H34.3801L39.9167 113.052H40.0294V104.976H42.7288Z"
			fill="white"
		/>
		<path
			d="M56.2868 109.472H53.5436C53.4935 109.116 53.3912 108.801 53.2367 108.525C53.0822 108.245 52.8839 108.006 52.6417 107.81C52.3995 107.613 52.1198 107.463 51.8025 107.358C51.4893 107.254 51.149 107.202 50.7816 107.202C50.1177 107.202 49.5394 107.367 49.0467 107.697C48.554 108.023 48.172 108.5 47.9006 109.127C47.6292 109.75 47.4935 110.506 47.4935 111.397C47.4935 112.312 47.6292 113.082 47.9006 113.705C48.1761 114.327 48.5603 114.798 49.053 115.115C49.5457 115.433 50.1156 115.592 50.7628 115.592C51.1261 115.592 51.4622 115.544 51.7712 115.448C52.0843 115.352 52.362 115.212 52.6041 115.028C52.8463 114.84 53.0467 114.612 53.2054 114.344C53.3682 114.077 53.481 113.771 53.5436 113.429L56.2868 113.441C56.2159 114.031 56.0384 114.599 55.7545 115.147C55.4747 115.69 55.0969 116.177 54.6209 116.608C54.149 117.034 53.5854 117.373 52.9298 117.624C52.2785 117.87 51.5415 117.994 50.719 117.994C49.5749 117.994 48.5519 117.735 47.65 117.216C46.7523 116.698 46.0425 115.947 45.5206 114.965C45.0028 113.983 44.744 112.793 44.744 111.397C44.744 109.996 45.007 108.805 45.5331 107.823C46.0592 106.84 46.7732 106.092 47.6751 105.578C48.577 105.059 49.5916 104.8 50.719 104.8C51.4622 104.8 52.1511 104.905 52.7858 105.114C53.4246 105.323 53.9904 105.628 54.4831 106.029C54.9758 106.426 55.3766 106.913 55.6856 107.49C55.9987 108.067 56.1992 108.728 56.2868 109.472Z"
			fill="white"
		/>
		<path
			d="M58.2315 117.818V104.976H60.9434V115.579H66.4424V117.818H58.2315Z"
			fill="white"
		/>
		<path
			d="M76.1612 104.976H78.8731V113.316C78.8731 114.252 78.6497 115.072 78.2029 115.774C77.7604 116.476 77.1403 117.024 76.3428 117.417C75.5453 117.806 74.6163 118 73.5557 118C72.491 118 71.5599 117.806 70.7624 117.417C69.9649 117.024 69.3449 116.476 68.9023 115.774C68.4597 115.072 68.2384 114.252 68.2384 113.316V104.976H70.9503V113.084C70.9503 113.573 71.0568 114.008 71.2697 114.388C71.4868 114.768 71.7916 115.067 72.1841 115.285C72.5766 115.502 73.0338 115.611 73.5557 115.611C74.0818 115.611 74.539 115.502 74.9274 115.285C75.3198 115.067 75.6226 114.768 75.8355 114.388C76.0526 114.008 76.1612 113.573 76.1612 113.084V104.976Z"
			fill="white"
		/>
		<path
			d="M81.1043 117.818V104.976H86.2401C87.1837 104.976 87.9707 105.116 88.6012 105.396C89.2317 105.676 89.7056 106.065 90.0229 106.562C90.3403 107.055 90.499 107.624 90.499 108.268C90.499 108.769 90.3987 109.21 90.1983 109.591C89.9979 109.967 89.7223 110.276 89.3716 110.519C89.025 110.757 88.6284 110.927 88.1816 111.027V111.152C88.6701 111.173 89.1273 111.311 89.5532 111.566C89.9833 111.821 90.3319 112.179 90.5992 112.638C90.8664 113.094 91 113.638 91 114.269C91 114.95 90.8309 115.559 90.4927 116.094C90.1587 116.625 89.6639 117.045 89.0083 117.354C88.3528 117.663 87.5449 117.818 86.5845 117.818H81.1043ZM83.8162 115.598H86.0271C86.7829 115.598 87.334 115.454 87.6806 115.166C88.0271 114.873 88.2004 114.484 88.2004 113.999C88.2004 113.644 88.1148 113.33 87.9436 113.059C87.7724 112.787 87.5282 112.574 87.2108 112.419C86.8977 112.264 86.524 112.187 86.0897 112.187H83.8162V115.598ZM83.8162 110.35H85.8267C86.1983 110.35 86.5282 110.285 86.8163 110.155C87.1085 110.021 87.3382 109.833 87.5052 109.591C87.6764 109.348 87.762 109.058 87.762 108.719C87.762 108.255 87.5971 107.881 87.2672 107.597C86.9415 107.312 86.478 107.17 85.8768 107.17H83.8162V110.35Z"
			fill="white"
		/>
		<path
			d="M149 47.023C149 57.3563 140.953 65.666 131.107 65.666C121.261 65.666 113.214 57.3563 113.214 47.023C113.214 36.6898 121.261 28.3801 131.107 28.3801C140.953 28.3801 149 36.6898 149 47.023Z"
			fill="#4F1649"
			stroke="black"
			stroke-width="2"
		/>
		<path
			d="M120.135 45.3333V41H123.055V41.7554H121.065V42.6017H121.781C122.129 42.6017 122.429 42.6574 122.681 42.7689C122.935 42.8789 123.13 43.0348 123.268 43.2365C123.405 43.4382 123.474 43.6759 123.474 43.9495C123.474 44.2218 123.405 44.4623 123.268 44.6711C123.13 44.8784 122.935 45.0406 122.681 45.1577C122.429 45.2748 122.129 45.3333 121.781 45.3333H120.135ZM121.065 44.597H121.781C121.947 44.597 122.087 44.5674 122.202 44.5081C122.317 44.4475 122.403 44.3678 122.462 44.269C122.522 44.1689 122.552 44.0596 122.552 43.9411C122.552 43.769 122.486 43.6237 122.352 43.5052C122.221 43.3853 122.03 43.3254 121.781 43.3254H121.065V44.597Z"
			fill="white"
		/>
		<path
			d="M124.834 45.3333H123.837L125.356 41H126.555L128.072 45.3333H127.075L125.973 41.9902H125.939L124.834 45.3333ZM124.772 43.63H127.127V44.3452H124.772V43.63Z"
			fill="white"
		/>
		<path
			d="M128.363 45.3333V44.597L128.488 44.5864C128.648 44.5737 128.778 44.5138 128.877 44.4066C128.975 44.298 129.049 44.1252 129.098 43.8882C129.148 43.6498 129.18 43.3296 129.195 42.9276L129.268 41H132.254V45.3333H131.35V41.7342H130.131L130.063 43.2428C130.04 43.7281 129.981 44.1245 129.886 44.432C129.793 44.7395 129.644 44.9666 129.44 45.1133C129.236 45.26 128.958 45.3333 128.604 45.3333H128.363Z"
			fill="white"
		/>
		<path
			d="M132.783 45.3333V44.597L132.907 44.5864C133.068 44.5737 133.197 44.5138 133.296 44.4066C133.395 44.298 133.469 44.1252 133.517 43.8882C133.567 43.6498 133.6 43.3296 133.614 42.9276L133.687 41H136.673V45.3333H135.769V41.7342H134.551L134.482 43.2428C134.459 43.7281 134.4 44.1245 134.306 44.432C134.213 44.7395 134.064 44.9666 133.859 45.1133C133.656 45.26 133.377 45.3333 133.023 45.3333H132.783Z"
			fill="white"
		/>
		<path
			d="M138.053 42.6017H139.101C139.451 42.6017 139.751 42.6574 140.004 42.7689C140.257 42.8789 140.453 43.0348 140.59 43.2365C140.728 43.4382 140.796 43.6759 140.794 43.9495C140.796 44.2218 140.728 44.4623 140.59 44.6711C140.453 44.8784 140.257 45.0406 140.004 45.1577C139.751 45.2748 139.451 45.3333 139.101 45.3333H137.458V41H138.386V44.597H139.101C139.269 44.597 139.41 44.5674 139.524 44.5081C139.639 44.4475 139.726 44.3678 139.784 44.269C139.845 44.1689 139.874 44.0596 139.872 43.9411C139.874 43.769 139.808 43.6237 139.675 43.5052C139.543 43.3853 139.352 43.3254 139.101 43.3254H138.053V42.6017ZM142.081 41V45.3333H141.153V41H142.081Z"
			fill="white"
		/>
		<path
			d="M118.282 53.9284L117 48.8692H118.035L118.777 52.3844H118.814L119.633 48.8692H120.519L121.335 52.3918H121.374L122.116 48.8692H123.151L121.869 53.9284H120.946L120.092 50.6206H120.057L119.206 53.9284H118.282Z"
			fill="white"
		/>
		<path d="M124.606 48.8692V53.9284H123.658V48.8692H124.606Z" fill="white" />
		<path
			d="M129.134 48.8692V53.9284H128.316L126.366 50.7441H126.333V53.9284H125.386V48.8692H126.217L128.152 52.0509H128.191V48.8692H129.134Z"
			fill="white"
		/>
		<path
			d="M133.871 50.6404H132.913C132.895 50.5004 132.86 50.3761 132.806 50.2674C132.752 50.157 132.682 50.0632 132.598 49.9857C132.513 49.9083 132.415 49.8491 132.304 49.8079C132.195 49.7667 132.076 49.7461 131.948 49.7461C131.716 49.7461 131.514 49.8112 131.342 49.9413C131.169 50.0697 131.036 50.2575 130.941 50.5045C130.846 50.7499 130.799 51.048 130.799 51.3988C130.799 51.7594 130.846 52.0625 130.941 52.3078C131.037 52.5532 131.172 52.7385 131.344 52.8637C131.516 52.9888 131.715 53.0514 131.941 53.0514C132.068 53.0514 132.186 53.0325 132.294 52.9946C132.403 52.9567 132.5 52.9015 132.585 52.8291C132.669 52.755 132.739 52.6652 132.795 52.5598C132.852 52.4544 132.891 52.3342 132.913 52.1991L133.871 52.2041C133.847 52.4363 133.785 52.6603 133.685 52.876C133.588 53.0901 133.456 53.282 133.289 53.4516C133.124 53.6196 132.927 53.753 132.698 53.8518C132.471 53.9489 132.213 53.9975 131.926 53.9975C131.526 53.9975 131.169 53.8954 130.854 53.6912C130.54 53.487 130.292 53.1914 130.11 52.8044C129.929 52.4174 129.838 51.9488 129.838 51.3988C129.838 50.8471 129.93 50.3777 130.114 49.9907C130.298 49.6037 130.547 49.3089 130.862 49.1063C131.177 48.9021 131.532 48.8 131.926 48.8C132.186 48.8 132.426 48.8412 132.648 48.9235C132.871 49.0059 133.069 49.1261 133.241 49.2842C133.413 49.4406 133.553 49.6325 133.661 49.8598C133.771 50.087 133.841 50.3472 133.871 50.6404Z"
			fill="white"
		/>
		<path
			d="M134.551 53.9284V48.8692H135.498V53.0465H137.42V53.9284H134.551Z"
			fill="white"
		/>
		<path
			d="M140.815 48.8692H141.763V52.1547C141.763 52.5236 141.685 52.8464 141.529 53.123C141.374 53.3997 141.157 53.6155 140.879 53.7703C140.6 53.9234 140.276 54 139.905 54C139.533 54 139.208 53.9234 138.929 53.7703C138.65 53.6155 138.434 53.3997 138.279 53.123C138.124 52.8464 138.047 52.5236 138.047 52.1547V48.8692H138.995V52.0633C138.995 52.256 139.032 52.4272 139.106 52.5771C139.182 52.727 139.289 52.8447 139.426 52.9304C139.563 53.016 139.723 53.0588 139.905 53.0588C140.089 53.0588 140.249 53.016 140.384 52.9304C140.521 52.8447 140.627 52.727 140.702 52.5771C140.777 52.4272 140.815 52.256 140.815 52.0633V48.8692Z"
			fill="white"
		/>
		<path
			d="M142.542 53.9284V48.8692H144.337C144.667 48.8692 144.942 48.9243 145.162 49.0347C145.382 49.145 145.548 49.2982 145.659 49.4942C145.769 49.6885 145.825 49.9125 145.825 50.1661C145.825 50.3637 145.79 50.5375 145.72 50.6873C145.65 50.8355 145.554 50.9574 145.431 51.0529C145.31 51.1468 145.171 51.2135 145.015 51.253V51.3024C145.186 51.3107 145.346 51.365 145.495 51.4655C145.645 51.5659 145.767 51.7067 145.86 51.8879C145.953 52.0674 146 52.2815 146 52.5302C146 52.7986 145.941 53.0382 145.823 53.249C145.706 53.4582 145.533 53.6237 145.304 53.7456C145.075 53.8674 144.793 53.9284 144.457 53.9284H142.542ZM143.49 53.0539H144.262C144.527 53.0539 144.719 52.9971 144.84 52.8834C144.961 52.7681 145.022 52.615 145.022 52.4239C145.022 52.284 144.992 52.1604 144.932 52.0534C144.872 51.9464 144.787 51.8624 144.676 51.8014C144.567 51.7405 144.436 51.71 144.284 51.71H143.49V53.0539ZM143.49 50.9862H144.192C144.322 50.9862 144.438 50.9607 144.538 50.9096C144.64 50.8569 144.721 50.7828 144.779 50.6873C144.839 50.5918 144.869 50.4773 144.869 50.3439C144.869 50.1611 144.811 50.0137 144.696 49.9018C144.582 49.7898 144.42 49.7338 144.21 49.7338H143.49V50.9862Z"
			fill="white"
		/>
		<path
			d="M63.1222 19.6429C63.1222 29.9761 55.0748 38.2858 45.229 38.2858C35.3832 38.2858 27.3358 29.9761 27.3358 19.6429C27.3358 9.30968 35.3832 1 45.229 1C55.0748 1 63.1222 9.30968 63.1222 19.6429Z"
			fill="#4F1649"
			stroke="black"
			stroke-width="2"
		/>
		<path
			d="M33.3514 17.3333V13H36.4725V13.7554H34.3458V14.6017H35.1106C35.4826 14.6017 35.8034 14.6574 36.0729 14.7689C36.3439 14.8789 36.5528 15.0348 36.6998 15.2365C36.8468 15.4382 36.9203 15.6759 36.9203 15.9495C36.9203 16.2218 36.8468 16.4623 36.6998 16.6711C36.5528 16.8784 36.3439 17.0406 36.0729 17.1577C35.8034 17.2748 35.4826 17.3333 35.1106 17.3333H33.3514ZM34.3458 16.597H35.1106C35.2882 16.597 35.4382 16.5674 35.5607 16.5081C35.6832 16.4475 35.7758 16.3678 35.8386 16.269C35.9029 16.1689 35.9351 16.0596 35.9351 15.9411C35.9351 15.769 35.8639 15.6237 35.7215 15.5052C35.5806 15.3853 35.377 15.3254 35.1106 15.3254H34.3458V16.597Z"
			fill="white"
		/>
		<path
			d="M38.3746 17.3333H37.309L38.9327 13H40.2142L41.8357 17.3333H40.77L39.5919 13.9902H39.5551L38.3746 17.3333ZM38.308 15.63H40.8251V16.3452H38.308V15.63Z"
			fill="white"
		/>
		<path
			d="M42.1469 17.3333V16.597L42.2801 16.5864C42.4515 16.5737 42.5901 16.5138 42.6957 16.4066C42.8014 16.298 42.8802 16.1252 42.9323 15.8882C42.9859 15.6498 43.0203 15.3296 43.0356 14.9276L43.1137 13H46.306V17.3333H45.3392V13.7342H44.037L43.9635 15.2428C43.939 15.7281 43.8762 16.1245 43.7752 16.432C43.6756 16.7395 43.5164 16.9666 43.2975 17.1133C43.08 17.26 42.7823 17.3333 42.4041 17.3333H42.1469Z"
			fill="white"
		/>
		<path
			d="M46.871 17.3333V16.597L47.0042 16.5864C47.1757 16.5737 47.3143 16.5138 47.4199 16.4066C47.5256 16.298 47.6044 16.1252 47.6565 15.8882C47.71 15.6498 47.7445 15.3296 47.7598 14.9276L47.8379 13H51.0302V17.3333H50.0633V13.7342H48.7611L48.6876 15.2428C48.6631 15.7281 48.6004 16.1245 48.4993 16.432C48.3998 16.7395 48.2406 16.9666 48.0216 17.1133C47.8042 17.26 47.5064 17.3333 47.1282 17.3333H46.871Z"
			fill="white"
		/>
		<path
			d="M52.5046 14.6017H53.6254C53.999 14.6017 54.3205 14.6574 54.59 14.7689C54.861 14.8789 55.07 15.0348 55.217 15.2365C55.3639 15.4382 55.4367 15.6759 55.4351 15.9495C55.4367 16.2218 55.3639 16.4623 55.217 16.6711C55.07 16.8784 54.861 17.0406 54.59 17.1577C54.3205 17.2748 53.999 17.3333 53.6254 17.3333H51.8685V13H52.8606V16.597H53.6254C53.8045 16.597 53.9553 16.5674 54.0778 16.5081C54.2003 16.4475 54.2929 16.3678 54.3557 16.269C54.42 16.1689 54.4514 16.0596 54.4499 15.9411C54.4514 15.769 54.381 15.6237 54.2386 15.5052C54.0977 15.3853 53.8933 15.3254 53.6254 15.3254H52.5046V14.6017ZM56.8108 13V17.3333H55.8187V13H56.8108Z"
			fill="white"
		/>
		<path
			d="M31.3708 25.9284L30 20.8692H31.1065L31.8995 24.3844H31.9392L32.8141 20.8692H33.7615L34.634 24.3918H34.6761L35.4691 20.8692H36.5756L35.2048 25.9284H34.2176L33.3053 22.6206H33.2679L32.3579 25.9284H31.3708Z"
			fill="white"
		/>
		<path d="M38.1306 20.8692V25.9284H37.1177V20.8692H38.1306Z" fill="white" />
		<path
			d="M42.971 20.8692V25.9284H42.0961L40.0119 22.7441H39.9768V25.9284H38.9639V20.8692H39.8528L41.9207 24.0509H41.9628V20.8692H42.971Z"
			fill="white"
		/>
		<path
			d="M48.0348 22.6404H47.0103C46.9915 22.5004 46.9533 22.3761 46.8956 22.2674C46.8379 22.157 46.7639 22.0632 46.6734 21.9857C46.583 21.9083 46.4785 21.8491 46.36 21.8079C46.243 21.7667 46.1159 21.7461 45.9787 21.7461C45.7307 21.7461 45.5147 21.8112 45.3307 21.9413C45.1467 22.0697 45.004 22.2575 44.9026 22.5045C44.8013 22.7499 44.7506 23.048 44.7506 23.3988C44.7506 23.7594 44.8013 24.0625 44.9026 24.3078C45.0055 24.5532 45.149 24.7385 45.333 24.8637C45.5171 24.9888 45.7299 25.0514 45.9716 25.0514C46.1073 25.0514 46.2329 25.0325 46.3483 24.9946C46.4652 24.9567 46.5689 24.9015 46.6594 24.8291C46.7498 24.755 46.8247 24.6652 46.8839 24.5598C46.9448 24.4544 46.9869 24.3342 47.0103 24.1991L48.0348 24.2041C48.0083 24.4363 47.9421 24.6603 47.836 24.876C47.7315 25.0901 47.5904 25.282 47.4126 25.4516C47.2364 25.6196 47.0259 25.753 46.781 25.8518C46.5377 25.9489 46.2625 25.9975 45.9553 25.9975C45.528 25.9975 45.1459 25.8954 44.8091 25.6912C44.4738 25.487 44.2086 25.1914 44.0137 24.8044C43.8203 24.4174 43.7236 23.9488 43.7236 23.3988C43.7236 22.8471 43.8219 22.3777 44.0184 21.9907C44.2149 21.6037 44.4816 21.3089 44.8184 21.1063C45.1553 20.9021 45.5342 20.8 45.9553 20.8C46.2329 20.8 46.4902 20.8412 46.7272 20.9235C46.9658 21.0059 47.1771 21.1261 47.3611 21.2842C47.5452 21.4406 47.6949 21.6325 47.8103 21.8598C47.9272 22.087 48.0021 22.3472 48.0348 22.6404Z"
			fill="white"
		/>
		<path
			d="M48.7612 25.9284V20.8692H49.7741V25.0465H51.8279V25.9284H48.7612Z"
			fill="white"
		/>
		<path
			d="M55.4578 20.8692H56.4707V24.1547C56.4707 24.5236 56.3872 24.8464 56.2204 25.123C56.0551 25.3997 55.8235 25.6155 55.5256 25.7703C55.2278 25.9234 54.8808 26 54.4847 26C54.087 26 53.7392 25.9234 53.4414 25.7703C53.1435 25.6155 52.9119 25.3997 52.7466 25.123C52.5813 24.8464 52.4987 24.5236 52.4987 24.1547V20.8692H53.5116V24.0633C53.5116 24.256 53.5513 24.4272 53.6309 24.5771C53.7119 24.727 53.8258 24.8447 53.9724 24.9304C54.119 25.016 54.2897 25.0588 54.4847 25.0588C54.6812 25.0588 54.8519 25.016 54.997 24.9304C55.1436 24.8447 55.2566 24.727 55.3361 24.5771C55.4172 24.4272 55.4578 24.256 55.4578 24.0633V20.8692Z"
			fill="white"
		/>
		<path
			d="M57.304 25.9284V20.8692H59.2222C59.5746 20.8692 59.8686 20.9243 60.1041 21.0347C60.3396 21.145 60.5166 21.2982 60.6351 21.4942C60.7536 21.6885 60.8129 21.9125 60.8129 22.1661C60.8129 22.3637 60.7754 22.5375 60.7006 22.6873C60.6257 22.8355 60.5228 22.9574 60.3918 23.0529C60.2624 23.1468 60.1142 23.2135 59.9473 23.253V23.3024C60.1298 23.3107 60.3006 23.365 60.4596 23.4655C60.6203 23.5659 60.7505 23.7067 60.8503 23.8879C60.9501 24.0674 61 24.2815 61 24.5302C61 24.7986 60.9368 25.0382 60.8105 25.249C60.6858 25.4582 60.501 25.6237 60.2561 25.7456C60.0113 25.8674 59.7095 25.9284 59.3508 25.9284H57.304ZM58.3169 25.0539H59.1427C59.4249 25.0539 59.6308 24.9971 59.7602 24.8834C59.8896 24.7681 59.9544 24.615 59.9544 24.4239C59.9544 24.284 59.9224 24.1604 59.8585 24.0534C59.7945 23.9464 59.7033 23.8624 59.5848 23.8014C59.4678 23.7405 59.3282 23.71 59.166 23.71H58.3169V25.0539ZM58.3169 22.9862H59.0678C59.2066 22.9862 59.3298 22.9607 59.4374 22.9096C59.5466 22.8569 59.6323 22.7828 59.6947 22.6873C59.7587 22.5918 59.7906 22.4773 59.7906 22.3439C59.7906 22.1611 59.729 22.0137 59.6058 21.9018C59.4842 21.7898 59.3111 21.7338 59.0865 21.7338H58.3169V22.9862Z"
			fill="white"
		/>
	</svg>
</template>
