<template>
	<svg
		width="142"
		height="142"
		viewBox="0 0 142 142"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0_2019_1384)">
			<path
				d="M71.008 141.211C109.78 141.211 141.211 109.78 141.211 71.0081C141.211 32.2361 109.78 0.805267 71.008 0.805267C32.236 0.805267 0.805176 32.2361 0.805176 71.0081C0.805176 109.78 32.236 141.211 71.008 141.211Z"
				fill="#561750"
			/>
			<path
				d="M71.008 141.211C109.78 141.211 141.211 109.78 141.211 71.0081C141.211 32.2361 109.78 0.805267 71.008 0.805267C32.236 0.805267 0.805176 32.2361 0.805176 71.0081C0.805176 109.78 32.236 141.211 71.008 141.211Z"
				stroke="#717171"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M110.965 13.2868C123.608 27.8782 135.268 46.4314 141.179 68.9788V69.011"
				stroke="#717171"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M92.4763 4.17126C112.833 23.5781 134.414 53.0507 137.796 92.6213"
				stroke="#717171"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M79.0605 1.27234C102.929 21.9676 131.531 57.1737 131.322 106.891"
				stroke="#717171"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M68.1572 0.86969C93.7324 21.8548 127.425 60.4913 124.22 116.779"
				stroke="#717171"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M58.7681 1.86823C85.1968 22.8373 122.384 63.5674 117.005 124.01"
				stroke="#717171"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M50.5381 3.83307C77.3855 24.7699 116.57 66.6274 109.854 129.47"
				stroke="#717171"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M43.2263 6.52264C70.2509 27.4595 110.176 69.8163 102.816 133.577"
				stroke="#717171"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M36.6555 9.79199C63.664 30.8255 103.235 73.1017 95.9229 136.653"
				stroke="#717171"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M30.6804 13.5284C57.544 34.7068 95.9228 76.4999 89.0781 138.843"
				stroke="#717171"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M89.6578 131.612C78.7868 135.574 66.9011 138.328 54.1619 139.149"
				stroke="#717171"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M35.979 131.854C55.869 133.207 73.9713 129.631 89.8672 123.56"
				stroke="#717171"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M24.4475 123.527C48.847 127.103 70.7662 123.092 89.561 115.652"
				stroke="#717171"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M16.4595 115.185C43.8062 120.532 68.2056 116.248 88.7882 107.921"
				stroke="#717171"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M10.7903 107.084C39.9569 113.687 65.9025 109.242 87.564 100.352"
				stroke="#717171"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M6.74805 99.2728C36.881 106.617 63.6479 102.14 85.8893 92.9756"
				stroke="#717171"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M3.94556 91.7516C34.3684 99.3855 61.3769 94.9888 83.7793 85.7927"
				stroke="#717171"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M2.10962 84.4721C32.2103 92.0254 58.9612 87.7736 81.2508 78.7708"
				stroke="#717171"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M1.09497 77.3858C30.3582 84.5204 56.4487 80.5102 78.3035 71.8939"
				stroke="#717171"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M8.84164 79.0285C7.64985 63.1004 9.14764 45.9966 15.1227 28.5063"
				stroke="#717171"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M16.4273 80.1881C14.8007 60.0243 17.4258 37.8796 28.0392 15.4933"
				stroke="#717171"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M24.0127 80.8484C22.3378 60.9584 24.7858 39.1035 34.9804 17.0071"
				stroke="#717171"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M31.534 81.0738C29.9879 62.5528 31.9849 42.3407 40.4885 21.8065"
				stroke="#717171"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M39.0389 80.8161C37.6539 63.9378 39.2644 45.6745 46.0608 27.0407"
				stroke="#717171"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M46.4957 80.1075C45.3683 65.1779 46.6084 49.1693 51.6977 32.8225"
				stroke="#717171"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M53.9363 78.9318C53.115 66.3375 54.0008 53.0024 57.4473 39.3451"
				stroke="#717171"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M61.3609 77.3213C60.8455 67.5293 61.3931 57.3186 63.3741 46.8663"
				stroke="#717171"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M68.7854 75.2437C68.576 68.9305 68.7854 62.4561 69.5584 55.853"
				stroke="#717171"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M30.6804 13.5284C57.544 34.7068 95.9228 76.4999 89.0781 138.843"
				stroke="#717171"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M30.6804 13.5284C57.544 34.7068 95.9228 76.4999 89.0781 138.843"
				stroke="#717171"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M1.09497 77.3858C30.3582 84.5204 56.4487 80.5102 78.3035 71.8939"
				stroke="#717171"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_2019_1384">
				<rect width="142" height="142" fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
