<script setup>
	import IconCheck from "./components/icons/IconCheck.vue";
	import AppNavigation from "./components/AppNavigation.vue";
	import NavigationButton from "./components/NavigationButton.vue";
	import IconClock from "./components/icons/IconClock.vue";
	import IconTicket from "./components/icons/IconTicket.vue";
	import IconPrize from "./components/icons/IconPrize.vue";
	import IconQuestions from "./components/icons/IconQuestions.vue";
	import PreloaderScreen from "./components/PreloaderScreen.vue";
	import { onMounted, ref, watch } from "vue";
	import { useUserStore } from "./store/userStore";
	import { getClientHasPhone } from "./services/apiService";
	import PlugScreen from "./components/PlugScreen.vue";
	import { useRoute } from "vue-router";
	import PlugScreenTech from "./components/PlugScreenTech.vue";
	import PlugScreenFinal from "./components/PlugScreenFinal.vue";
	// import UAParser from "ua-parser-js";

	const userStore = useUserStore();
	const isLoading = ref(true);
	const userId = ref(null);
	const isPlug = ref(false);
	// const getDeviceType = () => {
	// 	let parser = new UAParser();
	// 	let parserResults = parser.getResult();
	// 	if (parserResults.device.type) {
	// 		if (
	// 			parserResults.device.type === "mobile" ||
	// 			parserResults.device.type === "tablet"
	// 		) {
	// 			userStore.setDeviceType("mobile");
	// 		} else {
	// 			userStore.setDeviceType("desktop");
	// 		}
	// 	} else {
	// 		// Если UAParser не может определить тип устройства, делаем это вручную
	// 		const userAgent = navigator.userAgent;

	// 		if (/Mobi|Android|iPhone/i.test(userAgent)) {
	// 			userStore.setDeviceType("mobile");
	// 		} else {
	// 			userStore.setDeviceType("desktop");
	// 		}
	// 	}
	// };
	onMounted(async () => {
		// userId.value = "748306737";
		// userId.value = window.Telegram.WebApp.initDataUnsafe?.user?.id || null;
		userId.value = null;
		console.log(userId.value, window.Telegram.WebApp.initDataUnsafe?.user?.id);
		window.Telegram.WebApp.ready();
		// getDeviceType();
		if (
			userId.value === null ||
			userId.value === "" ||
			userId.value === undefined ||
			userId.value === false
		)
			return (isPlug.value = true);
		const hasPhone = await getClientHasPhone(userId.value);
		console.log(hasPhone, hasPhone.has_phone, userId.value);
		if (!hasPhone.has_phone) return (isPlug.value = true);
		userStore.setUserId(userId.value);
		userStore.setLoadedChecksToday(
			localStorage.getItem("checksLoadedToday") || 0
		);
		await loadData();
		watch(route, () => {
			userStore.updateAllData();
		});
	});

	const loadData = async () => {
		await userStore.updateAllData();

		setTimeout(() => {
			isLoading.value = false;
		}, 1500);
	};

	const route = useRoute();
</script>

<template>
	<PlugScreenTech v-if="false" is-loading />
	<PlugScreenFinal v-if="true" is-loading />
	<div v-else>
		<PlugScreen v-if="isPlug" is-loading />
		<PreloaderScreen v-else :is-loading="isLoading" />
		<div class="main">
			<router-view :is-loading="isLoading" />
			<AppNavigation>
				<NavigationButton to="/check">
					<IconCheck />
				</NavigationButton>
				<NavigationButton to="/history">
					<IconClock />
				</NavigationButton>
				<NavigationButton to="/" reverse-color>
					<IconTicket />
				</NavigationButton>
				<NavigationButton to="/prizes">
					<IconPrize />
				</NavigationButton>
				<NavigationButton to="/faq" reverse-color>
					<IconQuestions />
				</NavigationButton>
			</AppNavigation>
		</div>
	</div>
</template>

<style scoped lang="scss">
	.main {
		width: 100vw;
		height: 100vh;
		padding-top: 32px;
		background: url("@/assets/img/background-pattern.svg");
		overflow: auto;
		padding-bottom: 74px;
		// &--desktop {
		// 	padding-top: 0;
		// }
	}
</style>
