<template>
	<svg
		width="436"
		height="436"
		viewBox="0 0 436 436"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g opacity="0.4">
			<path
				d="M236.214 436H199.18L217.699 218L236.214 436Z"
				fill="url(#paint0_radial_188_11641)"
			/>
			<path
				d="M291.928 423.771L256.158 433.371L217.699 218L291.928 423.771Z"
				fill="url(#paint1_radial_188_11641)"
			/>
			<path
				d="M342.582 397.521L310.511 416.062L217.699 218L342.582 397.521Z"
				fill="url(#paint2_radial_188_11641)"
			/>
			<path
				d="M384.727 359.037L358.541 385.262L217.699 218L384.727 359.037Z"
				fill="url(#paint3_radial_188_11641)"
			/>
			<path
				d="M415.488 310.94L396.974 343.054L217.699 218L415.488 310.94Z"
				fill="url(#paint4_radial_188_11641)"
			/>
			<path
				d="M432.77 256.514L423.186 292.334L217.699 218L432.77 256.514Z"
				fill="url(#paint5_radial_188_11641)"
			/>
			<path
				d="M435.394 199.46V236.54L217.699 218L435.394 199.46Z"
				fill="url(#paint6_radial_188_11641)"
			/>
			<path
				d="M436 139.538L432.77 179.486L217.699 218L436 139.538Z"
				fill="url(#paint7_radial_188_11641)"
			/>
			<path
				d="M396.974 92.94L415.488 125.054L217.699 218L396.974 92.94Z"
				fill="url(#paint8_radial_188_11641)"
			/>
			<path
				d="M358.541 50.7377L384.727 76.9631L217.699 218L358.541 50.7377Z"
				fill="url(#paint9_radial_188_11641)"
			/>
			<path
				d="M310.511 19.9382L342.582 38.4786L217.699 218L310.511 19.9382Z"
				fill="url(#paint10_radial_188_11641)"
			/>
			<path
				d="M256.158 2.62904L291.928 12.2294L217.699 218L256.158 2.62904Z"
				fill="url(#paint11_radial_188_11641)"
			/>
			<path
				d="M199.18 0H236.214L217.699 218L199.18 0Z"
				fill="url(#paint12_radial_188_11641)"
			/>
			<path
				d="M143.47 12.2294L179.236 2.62904L217.699 218L143.47 12.2294Z"
				fill="url(#paint13_radial_188_11641)"
			/>
			<path
				d="M92.812 38.4786L124.883 19.9382L217.699 218L92.812 38.4786Z"
				fill="url(#paint14_radial_188_11641)"
			/>
			<path
				d="M50.6709 76.9631L76.8533 50.7377L217.699 218L50.6709 76.9631Z"
				fill="url(#paint15_radial_188_11641)"
			/>
			<path
				d="M19.9099 125.054L38.4245 92.94L217.699 218L19.9099 125.054Z"
				fill="url(#paint16_radial_188_11641)"
			/>
			<path
				d="M2.62418 179.486L12.2079 143.666L217.699 218L2.62418 179.486Z"
				fill="url(#paint17_radial_188_11641)"
			/>
			<path
				d="M0 236.54V199.46L217.699 218L0 236.54Z"
				fill="url(#paint18_radial_188_11641)"
			/>
			<path
				d="M12.2079 292.334L2.62418 256.514L217.699 218L12.2079 292.334Z"
				fill="url(#paint19_radial_188_11641)"
			/>
			<path
				d="M38.4245 343.054L19.9099 310.94L217.699 218L38.4245 343.054Z"
				fill="url(#paint20_radial_188_11641)"
			/>
			<path
				d="M76.8533 385.262L50.6709 359.037L217.699 218L76.8533 385.262Z"
				fill="url(#paint21_radial_188_11641)"
			/>
			<path
				d="M124.883 416.062L92.812 397.521L217.699 218L124.883 416.062Z"
				fill="url(#paint22_radial_188_11641)"
			/>
			<path
				d="M179.236 433.371L143.47 423.771L217.699 218L179.236 433.371Z"
				fill="url(#paint23_radial_188_11641)"
			/>
		</g>
		<defs>
			<radialGradient
				id="paint0_radial_188_11641"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(218 218) rotate(90) scale(218)"
			>
				<stop stop-color="#F58220" />
				<stop offset="1" stop-color="#F58220" stop-opacity="0" />
			</radialGradient>
			<radialGradient
				id="paint1_radial_188_11641"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(218 218) rotate(90) scale(218)"
			>
				<stop stop-color="#F58220" />
				<stop offset="1" stop-color="#F58220" stop-opacity="0" />
			</radialGradient>
			<radialGradient
				id="paint2_radial_188_11641"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(218 218) rotate(90) scale(218)"
			>
				<stop stop-color="#F58220" />
				<stop offset="1" stop-color="#F58220" stop-opacity="0" />
			</radialGradient>
			<radialGradient
				id="paint3_radial_188_11641"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(218 218) rotate(90) scale(218)"
			>
				<stop stop-color="#F58220" />
				<stop offset="1" stop-color="#F58220" stop-opacity="0" />
			</radialGradient>
			<radialGradient
				id="paint4_radial_188_11641"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(218 218) rotate(90) scale(218)"
			>
				<stop stop-color="#F58220" />
				<stop offset="1" stop-color="#F58220" stop-opacity="0" />
			</radialGradient>
			<radialGradient
				id="paint5_radial_188_11641"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(218 218) rotate(90) scale(218)"
			>
				<stop stop-color="#F58220" />
				<stop offset="1" stop-color="#F58220" stop-opacity="0" />
			</radialGradient>
			<radialGradient
				id="paint6_radial_188_11641"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(218 218) rotate(90) scale(218)"
			>
				<stop stop-color="#F58220" />
				<stop offset="1" stop-color="#F58220" stop-opacity="0" />
			</radialGradient>
			<radialGradient
				id="paint7_radial_188_11641"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(218 218) rotate(90) scale(218)"
			>
				<stop stop-color="#F58220" />
				<stop offset="1" stop-color="#F58220" stop-opacity="0" />
			</radialGradient>
			<radialGradient
				id="paint8_radial_188_11641"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(218 218) rotate(90) scale(218)"
			>
				<stop stop-color="#F58220" />
				<stop offset="1" stop-color="#F58220" stop-opacity="0" />
			</radialGradient>
			<radialGradient
				id="paint9_radial_188_11641"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(218 218) rotate(90) scale(218)"
			>
				<stop stop-color="#F58220" />
				<stop offset="1" stop-color="#F58220" stop-opacity="0" />
			</radialGradient>
			<radialGradient
				id="paint10_radial_188_11641"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(218 218) rotate(90) scale(218)"
			>
				<stop stop-color="#F58220" />
				<stop offset="1" stop-color="#F58220" stop-opacity="0" />
			</radialGradient>
			<radialGradient
				id="paint11_radial_188_11641"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(218 218) rotate(90) scale(218)"
			>
				<stop stop-color="#F58220" />
				<stop offset="1" stop-color="#F58220" stop-opacity="0" />
			</radialGradient>
			<radialGradient
				id="paint12_radial_188_11641"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(218 218) rotate(90) scale(218)"
			>
				<stop stop-color="#F58220" />
				<stop offset="1" stop-color="#F58220" stop-opacity="0" />
			</radialGradient>
			<radialGradient
				id="paint13_radial_188_11641"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(218 218) rotate(90) scale(218)"
			>
				<stop stop-color="#F58220" />
				<stop offset="1" stop-color="#F58220" stop-opacity="0" />
			</radialGradient>
			<radialGradient
				id="paint14_radial_188_11641"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(218 218) rotate(90) scale(218)"
			>
				<stop stop-color="#F58220" />
				<stop offset="1" stop-color="#F58220" stop-opacity="0" />
			</radialGradient>
			<radialGradient
				id="paint15_radial_188_11641"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(218 218) rotate(90) scale(218)"
			>
				<stop stop-color="#F58220" />
				<stop offset="1" stop-color="#F58220" stop-opacity="0" />
			</radialGradient>
			<radialGradient
				id="paint16_radial_188_11641"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(218 218) rotate(90) scale(218)"
			>
				<stop stop-color="#F58220" />
				<stop offset="1" stop-color="#F58220" stop-opacity="0" />
			</radialGradient>
			<radialGradient
				id="paint17_radial_188_11641"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(218 218) rotate(90) scale(218)"
			>
				<stop stop-color="#F58220" />
				<stop offset="1" stop-color="#F58220" stop-opacity="0" />
			</radialGradient>
			<radialGradient
				id="paint18_radial_188_11641"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(218 218) rotate(90) scale(218)"
			>
				<stop stop-color="#F58220" />
				<stop offset="1" stop-color="#F58220" stop-opacity="0" />
			</radialGradient>
			<radialGradient
				id="paint19_radial_188_11641"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(218 218) rotate(90) scale(218)"
			>
				<stop stop-color="#F58220" />
				<stop offset="1" stop-color="#F58220" stop-opacity="0" />
			</radialGradient>
			<radialGradient
				id="paint20_radial_188_11641"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(218 218) rotate(90) scale(218)"
			>
				<stop stop-color="#F58220" />
				<stop offset="1" stop-color="#F58220" stop-opacity="0" />
			</radialGradient>
			<radialGradient
				id="paint21_radial_188_11641"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(218 218) rotate(90) scale(218)"
			>
				<stop stop-color="#F58220" />
				<stop offset="1" stop-color="#F58220" stop-opacity="0" />
			</radialGradient>
			<radialGradient
				id="paint22_radial_188_11641"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(218 218) rotate(90) scale(218)"
			>
				<stop stop-color="#F58220" />
				<stop offset="1" stop-color="#F58220" stop-opacity="0" />
			</radialGradient>
			<radialGradient
				id="paint23_radial_188_11641"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(218 218) rotate(90) scale(218)"
			>
				<stop stop-color="#F58220" />
				<stop offset="1" stop-color="#F58220" stop-opacity="0" />
			</radialGradient>
		</defs>
	</svg>
</template>
