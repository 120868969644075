<template>
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M13.4851 2.80536C12.964 2.28427 12.0814 2.45424 11.7911 3.1316L10.3249 6.55263C9.12037 6.19573 7.76271 6.49244 6.81137 7.44379L5.68091 8.57424C5.43684 8.81831 5.43684 9.21404 5.68091 9.45812L7.46572 11.2429L2.68306 16.0256C2.43898 16.2697 2.43898 16.6654 2.68306 16.9095C2.92714 17.1535 3.32286 17.1535 3.56694 16.9095L8.34961 12.1268L10.1345 13.9117C10.3785 14.1557 10.7743 14.1557 11.0183 13.9117L12.1488 12.7812C13.1001 11.8299 13.3968 10.4722 13.0399 9.26763L16.461 7.80148C17.1383 7.51118 17.3083 6.62856 16.7872 6.10746L13.4851 2.80536Z"
			fill="#5F6062"
		/>
	</svg>
</template>
